import React, { useEffect } from "react";
import MenuDefaultClient from "../../components/organisms/Menu/Menu";
import Footer from "../../components/organisms/Footer.js/Footer";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import HeaderAfterMenu from "../../components/organisms/HeaderAfterMenu/HeaderAfterMenu";
import AboutTheRedCanidsPlatform from "../../components/organisms/AboutTheRedCanidsPlatform/AboutTheRedCanidsPlatform";
import TextRegularH6 from "../../components/atomos/TextRegularH6";
import { Flex, Space } from "antd";
import TextBoldH4 from "../../components/atomos/TextBoldH4";
import ImageRedCoins from "../../components/atomos/ImageRedCoins";
import CardHowItWorks from "../../components/atomos/CardHowItWorks";
import TextSemiboldH4 from "../../components/atomos/TextSemiboldH4";
import ImageRedExp from "../../components/atomos/ImageRedExp";
import checkIsMobile from "../../utils/CheckIsMobile";
import ButtonWallet from "../../components/atomos/ButtonWallet";
import CardEarnVideos from "../../components/organisms/CardEarnVideos/CardEarnVideos";
import ImageSmile from "../../components/atomos/ImageSmile";
import ImageRuby from "../../components/atomos/ImageRuby";
import ImageYoutubeRed from "../../components/atomos/ImageYoutubeRed";
import ImageRedCoinSvg from "../../components/atomos/ImageRedCoinSvg";
import ImageDoubts from "../../components/atomos/ImageDoubts";
import ImageGift from "../../components/atomos/ImageGift";
import ImageRedPocket from "../../components/atomos/ImageRedPocket";
import ImageCrown from "../../components/atomos/ImageCrown";

export default function HowItWorks() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    function handleNavigateMissions() {
        navigate("/missions")
    }
    const location = useLocation();
    const itemsBreadCrumb = [
        {
            title: "Home",
            path: "/home",
        },
        {
            title: "Como funciona",
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    const item = [{
        id: 1,
        title: 'Rufus te Ajuda! Passo-a-passo de como ganhar suas RED XP',
        image: '/images/movie-for-help1.png',
    }, {
        id: 2,
        title: 'Rufus te Ajuda! Passo-a-passo de como ganhar seus RED Coins',
        image: '/images/movie-for-help2.png',
    }, {
        id: 3,
        title: 'Rufus te Ajuda! Saiba como cumprir desafios diários e ganhar mais XP',
        image: '/images/movie-for-help3.png',
    }];

    return (
        <Flex vertical>
            <MenuDefaultClient page={location} />
            <HeaderAfterMenu
                title={"Como funciona a plataforma?"}
                items={itemsBreadCrumb}
            />
            <Flex align="center" justify="center">
                <AboutTheRedCanidsPlatform />
            </Flex>
            {
                !checkIsMobile() ? (
                    <Flex
                        style={{
                            justifyContent: "center",
                            width: "35.18%",
                            alignSelf: "center",
                            marginTop: 16,
                        }}
                    >
                        <TextRegularH6
                            text="Aqui trazemos uma visão geral da plataforma e os principais conceitos envolvendo
                            as missões, ganhos e resgates de produtos e benefícios"
                            textAlign={"center"}
                        />
                    </Flex>
                ) : (
                    <Flex
                        style={{
                            justifyContent: "center",
                            width: "85.18%",
                            alignSelf: "center",
                            marginTop: 16,
                        }}
                    >
                        <TextRegularH6
                            text="Aqui trazemos uma visão geral da plataforma e os principais conceitos envolvendo
            as missões, ganhos e resgates de produtos e benefícios"
                            textAlign={"center"}
                        />
                    </Flex>
                )
            }
            {!checkIsMobile() ? (
                <Flex vertical gap={30} style={{ marginTop: 36, width: "90%", alignSelf: "center" }}>
                    <Flex align="center" justify="space-between" style={{ width: "100%", alignSelf: "center", justifySelf: "center" }}>
                        <CardHowItWorks
                            onClick={() => navigate("/profile-page")}
                            icon={<ImageSmile />}
                            title={"Completando cadastro"}
                            description={"Após conseguir o convite é importante que você complete seu cadastro"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M9.41016 19.9201L15.9302 13.4001C16.7002 12.6301 16.7002 11.3701 15.9302 10.6001L9.41016 4.08008" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/missions")}
                            icon={<ImageRuby />}
                            title={"O que é"}
                            titleRed={"RED XP?"}
                            description={"Uma forma de pontuação para resgatar prêmios virtuais e cosméticos"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M9.41016 19.9201L15.9302 13.4001C16.7002 12.6301 16.7002 11.3701 15.9302 10.6001L9.41016 4.08008" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/missions")}
                            icon={<ImageYoutubeRed />}
                            title={"Como ganhar"}
                            titleRed={"RED XP?"}
                            description={"Você pode ganhar RED XP por desafios diários e missões de Farmar RED Coins e XP"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M9.41016 19.9201L15.9302 13.4001C16.7002 12.6301 16.7002 11.3701 15.9302 10.6001L9.41016 4.08008" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/missions")}
                            icon={<ImageRedCoinSvg />}
                            title={"O que é "}
                            titleRed={"RED Coins?"}
                            description={"É a moeda oficial da nossa plataforma de matilha, usada para trocar por produtos"}
                        />
                    </Flex>
                    <Flex align="center" justify="flex-end" style={{ width: "100%", alignSelf: "center", position: "relative" }}>
                        <Flex style={{ position: "absolute", right: "10%" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                <path d="M4.57992 8.91016L11.0999 15.4302C11.8699 16.2002 13.1299 16.2002 13.8999 15.4302L20.4199 8.91016" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </Flex>
                    </Flex>
                    <Flex align="center" justify="space-between" style={{ width: "100%", alignSelf: "center" }}>
                        <CardHowItWorks
                            onClick={() => navigate("/faq")}
                            icon={<ImageDoubts />}
                            title={"Ainda tem dúvidas?"}
                            description={"Temos uma área com as principais dúvidas e o suporte com Rufus!"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M15.5898 19.9201L9.06984 13.4001C8.29984 12.6301 8.29984 11.3701 9.06984 10.6001L15.5898 4.08008" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/use-red-coins")}
                            icon={<ImageGift />}
                            title={"Quando resgatar produtos?"}
                            description={"Uma vez que você tenha um nº de RED Coins você será avisado para resgatar"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M15.5898 19.9201L9.06984 13.4001C8.29984 12.6301 8.29984 11.3701 9.06984 10.6001L15.5898 4.08008" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/missions")}
                            icon={<ImageRedPocket />}
                            title={"Sobre as missões?"}
                            description={"São atividades que irão conceder um certo número de RED Coins ou RED XP"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M15.5898 19.9201L9.06984 13.4001C8.29984 12.6301 8.29984 11.3701 9.06984 10.6001L15.5898 4.08008" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/missions")}
                            icon={<ImageCrown />}
                            title={"Como ganhar"}
                            titleRed={"RED Coins?"}
                            description={"Assim como as RED XP, as RED Coins são adquiridas cumprindo missões"}
                        />
                    </Flex>
                </Flex>
            ) : (
                <Flex vertical gap={15} style={{ marginTop: 36, width: "80%", alignSelf: "center" }}>
                    <Flex vertical align="center" justify="center" style={{ width: "100%", alignSelf: "center", justifySelf: "center", gap: 15 }}>
                        <CardHowItWorks
                            onClick={() => navigate("/profile-page")}
                            icon={<ImageSmile />}
                            title={"Completando cadastro"}
                            description={"Após conseguir o convite é importante que você complete seu cadastro"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M4.57992 8.91016L11.0999 15.4302C11.8699 16.2002 13.1299 16.2002 13.8999 15.4302L20.4199 8.91016" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/missions")}
                            icon={<ImageRuby />}
                            title={"O que é"}
                            titleRed={"RED XP?"}
                            description={"Uma forma de pontuação para resgatar prêmios virtuais e cosméticos"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M4.57992 8.91016L11.0999 15.4302C11.8699 16.2002 13.1299 16.2002 13.8999 15.4302L20.4199 8.91016" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/missions")}
                            icon={<ImageYoutubeRed />}
                            title={"Como ganhar"}
                            titleRed={"RED XP?"}
                            description={"Você pode ganhar RED XP por desafios diários e missões de Farmar RED Coins e XP"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M4.57992 8.91016L11.0999 15.4302C11.8699 16.2002 13.1299 16.2002 13.8999 15.4302L20.4199 8.91016" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/missions")}
                            icon={<ImageRedCoinSvg />}
                            title={"O que é "}
                            titleRed={"RED Coins?"}
                            description={"É a moeda oficial da nossa plataforma de matilha, usada para trocar por produtos"}
                        />
                    </Flex>
                    <Flex align="center" justify="center" style={{ width: "80%", alignSelf: "center" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M4.57992 8.91016L11.0999 15.4302C11.8699 16.2002 13.1299 16.2002 13.8999 15.4302L20.4199 8.91016" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </Flex>
                    <Flex vertical align="center" justify="space-between" style={{ width: "100%", alignSelf: "center", gap: 15 }}>
                        <CardHowItWorks
                            onClick={() => navigate("/faq")}
                            icon={<ImageCrown />}
                            title={"Como ganhar"}
                            titleRed={"RED Coins?"}
                            description={"Assim como as RED XP, as RED Coins são adquiridas cumprindo missões"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M4.57992 8.91016L11.0999 15.4302C11.8699 16.2002 13.1299 16.2002 13.8999 15.4302L20.4199 8.91016" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/missions")}
                            icon={<ImageRedPocket />}
                            title={"Sobre as missões?"}
                            description={"São atividades que irão conceder um certo número de RED Coins ou RED XP"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M4.57992 8.91016L11.0999 15.4302C11.8699 16.2002 13.1299 16.2002 13.8999 15.4302L20.4199 8.91016" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/use-red-coins")}
                            icon={<ImageGift />}
                            title={"Quando resgatar produtos?"}
                            description={"Uma vez que você tenha um nº de RED Coins você será avisado para resgatar"}
                        />
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                            <path d="M4.57992 8.91016L11.0999 15.4302C11.8699 16.2002 13.1299 16.2002 13.8999 15.4302L20.4199 8.91016" stroke="#FE304E" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <CardHowItWorks
                            onClick={() => navigate("/faq")}
                            icon={<ImageDoubts />}
                            title={"Ainda tem dúvidas?"}
                            description={"Temos uma área com as principais dúvidas e o suporte com Rufus!"}
                        />
                    </Flex>
                </Flex>
            )
            }


            {
                !checkIsMobile() ? (
                    <Flex
                        style={{
                            justifyContent: "center", width: "80%", alignSelf: "center", marginTop: 16, flexDirection: "column"
                        }}>
                        <TextSemiboldH4 text={"Pra resumir e facilitar, temos:"} colorText={"white"} />
                        <Flex align="start" gap={30} justify="space-evenly" style={{ marginTop: 36 }}>
                            <Flex
                                style={{
                                    justifyContent: "center", width: "40%", alignItems: "center", marginTop: 16, gap: 16, flexDirection: "column"
                                }}>
                                <ImageRedExp
                                    width={128}
                                    height={128}
                                />
                                <TextBoldH4 text={"RED XP"}
                                    colorText={"white"} />
                                <TextRegularH6 text={"Que você pode ganhar assistindo vídeos, e cumprindo desafios diários e pode ser usado para desbloquear prêmios digitais, como avatares,capas de perfil e outros presentes"}
                                    colorText={"white"} />
                                {/* <ButtonWallet text={"Cumprir missões"} buttonAction={handleNavigateMissions} colorText={"white"} textAlign={"center"} /> */}
                            </Flex>
                            <Flex style={{ justifyContent: "center", marginTop: 46, flexDirection: "row" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="53" viewBox="0 0 54 53" fill="none">
                                    <path d="M27.0199 52.6722C23.0302 52.6722 19.7959 49.4379 19.7959 45.4482V8.15222C19.7959 4.16251 23.0302 0.928223 27.0199 0.928223C31.0096 0.928223 34.2439 4.16252 34.2439 8.15222V45.4482C34.2439 49.4379 31.0096 52.6722 27.0199 52.6722ZM7.25192 33.6322C3.47871 33.6322 0.419922 30.5734 0.419922 26.8002C0.419922 23.027 3.47871 19.9682 7.25192 19.9682H46.8999C50.6731 19.9682 53.7319 23.027 53.7319 26.8002C53.7319 30.5734 50.6731 33.6322 46.8999 33.6322H7.25192Z" fill="#FE304E" />
                                </svg>
                            </Flex>
                            <Flex
                                style={{
                                    justifyContent: "center", width: "40%", alignItems: "center", marginTop: 16, gap: 16, flexDirection: "column"
                                }}>

                                <ImageRedCoins
                                    width={128}
                                    height={128}
                                />
                                <TextBoldH4 text={"RED Coins"}
                                    colorText={"white"} />
                                <TextRegularH6
                                    text={"Que você pode ganhar cumprindo missões específicas, que podem ter um prazo máximo. Com elas, você pode trocar por produtos de nossa loja quando você atingir uma quantidade mínima"}
                                    colorText={"white"} />
                            </Flex>
                        </Flex>
                        <Flex style={{ marginTop: 20 }}>
                            <ButtonWallet text={"Cumprir missões"} buttonAction={handleNavigateMissions} colorText={"white"} textAlign={"center"} />
                        </Flex>
                    </Flex>
                ) : (

                    <Flex vertical
                        style={{
                            justifyContent: "center", width: "80%", alignSelf: "center", marginTop: 16, flexDirection: "column"
                        }}>
                        <TextSemiboldH4 text={"Pra resumir e facilitar, temos:"} colorText={"white"} />
                        <Flex vertical align="center">
                            <Flex
                                style={{
                                    justifyContent: "center", width: "100%", alignItems: "center", marginTop: 16, gap: 16, flexDirection: "column"
                                }}>
                                <ImageRedExp
                                    width={128}
                                    height={128}
                                />
                                <TextBoldH4 text={"RED XP"}
                                    colorText={"white"} />
                                <TextRegularH6 text={"Que você pode ganhar assistindo vídeos, e cumprindo desafios diários e pode ser usado para desbloquear prêmios digitais, como avatares,capas de perfil e outros presentes"}
                                    colorText={"white"} />
                                {/* <ButtonWallet text={"Cumprir missões"} buttonAction={handleNavigateMissions} colorText={"white"} textAlign={"center"} /> */}

                            </Flex>
                            <Flex style={{ justifyContent: "center", marginTop: 46, marginBottom: 46, flexDirection: "row" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="53" viewBox="0 0 54 53" fill="none">
                                    <path d="M27.0199 52.6722C23.0302 52.6722 19.7959 49.4379 19.7959 45.4482V8.15222C19.7959 4.16251 23.0302 0.928223 27.0199 0.928223C31.0096 0.928223 34.2439 4.16252 34.2439 8.15222V45.4482C34.2439 49.4379 31.0096 52.6722 27.0199 52.6722ZM7.25192 33.6322C3.47871 33.6322 0.419922 30.5734 0.419922 26.8002C0.419922 23.027 3.47871 19.9682 7.25192 19.9682H46.8999C50.6731 19.9682 53.7319 23.027 53.7319 26.8002C53.7319 30.5734 50.6731 33.6322 46.8999 33.6322H7.25192Z" fill="#FE304E" />
                                </svg>
                            </Flex>
                            <Flex
                                style={{
                                    justifyContent: "center", width: "100%", alignItems: "center", marginTop: 16, gap: 16, flexDirection: "column"
                                }}>
                                <ImageRedCoins
                                    width={128}
                                    height={128}
                                />
                                <TextBoldH4 text={"RED Coins"}
                                    colorText={"white"} />
                                <TextRegularH6
                                    text={"Que você pode ganhar cumprindo missões específicas, que podem ter um prazo máximo. Com elas, você pode trocar por produtos de nossa loja quando você atingir uma quantidade mínima"}
                                    colorText={"white"} />
                                <ButtonWallet text={"Cumprir missões"} buttonAction={handleNavigateMissions} colorText={"white"} textAlign={"center"} />

                            </Flex>
                        </Flex>
                    </Flex>
                )
            }
            {
                !checkIsMobile() ? (
                    <Flex vertical align="center" style={{ marginTop: 46, marginBottom: 46, width: "85%", margin: " 2% auto", justifyContent: "flex-start" }}>
                        <Space style={{ alignSelf: "flex-start", marginLeft: "0.5%" }}>
                            <TextSemiboldH4 text={"Vídeos que podem te ajudar"} colorText={"white"} textAlign={"left"} />
                        </Space>
                        <Flex horizontal align="center" style={{ marginTop: 16, gap: 16, flexDirection: "row" }}>
                            {item.map((item2) => {
                                return (
                                    <>
                                        <CardEarnVideos
                                            title={item2.title}
                                            image={item2.image} />
                                    </>
                                )
                            })}
                        </Flex>
                    </Flex>
                ) : (
                    <Flex vertical align="center" style={{ marginTop: 46, marginBottom: 46, width: "85%", margin: " 2% auto", justifyContent: "flex-start" }}>
                        <Space style={{ alignSelf: "flex-start", marginLeft: "0.5%" }}>
                            <TextSemiboldH4 text={"Vídeos que podem te ajudar"} colorText={"white"} textAlign={"left"} />
                        </Space>
                        <Flex vertical align="center" style={{ marginTop: 16, gap: 16, flexDirection: "column" }}>
                            {item.map((item2) => {
                                return (
                                    <>
                                        <CardEarnVideos
                                            title={item2.title}
                                            image={item2.image} />
                                    </>
                                )
                            })}
                        </Flex>
                    </Flex>
                )
            }
            <Footer />
        </Flex >
    );
} 