import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import MenuDefaultClient from "../../components/organisms/Menu/Menu";
import { ConfigProvider, Flex, Modal, Progress, Tour, Typography } from "antd";
import ImageFiveWolf from "../../components/atomos/ImageFiveWolf";
import HomeInfoUser from "../../components/organisms/HomeInfoUser/HomeInfoUser";
import HomeInfoUserCard from "../../components/organisms/HomeInfoUserCard/HomeInfoUserCard";
import ScoreboardFriends from "../../components/organisms/ScoreboardFriends/ScoreboardFriends";
import ChallengeList from "../../components/organisms/ChallengeList/ChallengeList";
import EarnWatchingVideo from "../../components/organisms/EarnWatchingVideo/EarnWatchingVideo";
import TitleEarnWathcingVideo from "../../components/organisms/TitleEarnWathcingVideo/TitleEarnWathcingVideo";
import MissionToWinRedCoins from "../../components/organisms/MissionToWinRedCoins/MissionToWinRedCoins";
import Footer from "../../components/organisms/Footer.js/Footer";
import ModalHomeWelcome from "../../components/organisms/ModalHomeWelcome/ModalHomeWelcome";
import ModalHomeWelcomeTwo from "../../components/organisms/ModalHomeWelcome/ModalHomeWelcomeTwo";
/* import MoreInfo from "../../components/organisms/MoreInfo/MoreInfo";
 */
import { useDispatch, useSelector } from "react-redux";
import { changeName } from "../../features/user/user";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { AccountService, MissionService } from "red-canids-client-module/client-web"
import { addMissions } from "../../features/mission/mission";
import account, { addUser } from "../../features/account/account";
//import * as JivoSite from "react-jivosite";
import ImageArtBoard12 from "../../components/atomos/ImageArtBoard12";
import ButtonWallet from "../../components/atomos/ButtonWallet";
import checkIsMobile from "../../utils/CheckIsMobile";
import CoverImage from "../../components/atomos/CoverImage";
import * as JivoSite from "react-jivosite";
import { encryptAES } from "../../utils/Crypt";

export default function HomePage(props) {
    const dispatch = useDispatch();
    const { session } = useParams();
    const navigate = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
    const [open, setOpen] = useState(false);
    const mission = useSelector((state) => state.mission);
    const account = useSelector((state) => state.account);
    const location = useLocation();
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const steps = [
        {
            nextButtonProps: {
                children: "Proximo",
                className: "custom-button",
            },
            target: () => ref1.current,
            style: { width: "70%" },
            cover:
                !checkIsMobile() ? (
                    <Flex
                        align="center"
                        justify="center"
                        style={{
                            background: "#0a0c0d",
                        }}>
                        <Flex vertical align="center" justify="center">
                            <ImageArtBoard12 width={110} height={144} />
                            <Typography style={{
                                color: "white",
                                textAlign: "left",
                                fontSize: 24,
                                fontStyle: "normal",
                                fontWeight: 700,
                            }}>
                                Rufus
                            </Typography>
                        </Flex>
                        <Flex vertical style={{ width: "100%" }}>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Progress: {
                                            defaultColor: "#FE304E"
                                        },
                                    },
                                }}
                            >
                                <Progress percent={30} />
                            </ConfigProvider>
                            <Typography style={{
                                color: "white",
                                textAlign: "left",
                                fontSize: 24,
                                fontStyle: "normal",
                                fontWeight: 700,
                            }}>
                                Área de perfil e Desafios diários
                            </Typography>
                            <Typography style={{
                                color: "white",
                                textAlign: "justify",
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: 600,
                            }}>
                                Nessa área você poderá editar seus dados cadastrais, acompanhar seus <span style={{ color: "red" }}>RED XP</span> e <span style={{ color: "red" }}> RED Coins</span>,
                                além de resgatar prêmios digitais com <span style={{ color: "red" }}> RED XP </span> através de atividades diárias
                            </Typography>
                        </Flex>
                    </Flex>
                ) : (
                    <Flex
                        align="center"
                        justify="center"
                        style={{
                            background: "#0a0c0d",
                        }}>
                        <Flex vertical align="center" justify="center">
                            <ImageArtBoard12 width={110} height={144} />
                            <Typography style={{
                                color: "white",
                                textAlign: "center",
                                fontSize: 24,
                                fontStyle: "normal",
                                fontWeight: 700,
                            }}>
                                Rufus
                            </Typography>
                            <Flex vertical style={{ width: "100%" }}>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Progress: {
                                                defaultColor: "#FE304E"
                                            },
                                        },
                                    }}
                                >
                                    <Progress percent={30} />
                                </ConfigProvider>
                                <Typography style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: 24,
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                }}>
                                    Área de perfil e Desafios diários
                                </Typography>
                                <Typography style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: 16,
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                }}>
                                    Nessa área você poderá editar seus dados cadastrais, acompanhar seus <span style={{ color: "red" }}>RED XP</span> e <span style={{ color: "red" }}> RED Coins</span>,
                                    além de resgatar prêmios digitais com <span style={{ color: "red" }}> RED XP </span> através de atividades diárias
                                </Typography>
                            </Flex>
                        </Flex>
                    </Flex>
                )
        },
        /*  {
             target: () => ref2.current,
             prevButtonProps: {
                 children: "Pular tutorial",
                 className: "custom-button2",
                 onClick: () => {
                     setOpen(false);
                     setIsModalOpenTwo(true);
                 }
             },
             nextButtonProps: {
                 children: "Proximo",
                 className: "custom-button",
             },
             style: { width: "70%" },
             cover:
                 !checkIsMobile() ? (
                     <Flex>
                         <Flex vertical align="center" justify="center">
                             <ImageArtBoard12 width={144} height={144} />
                             <Typography style={{
                                 color: "white",
                                 textAlign: "left",
                                 fontSize: 24,
                                 fontStyle: "normal",
                                 fontWeight: 700,
                             }}>
                                 Rufus
                             </Typography>
                         </Flex>
                         <Flex vertical style={{ width: "100%" }}>
                             <ConfigProvider
                                 theme={{
                                     components: {
                                         Progress: {
                                             defaultColor: "#FE304E"
                                         },
                                     },
                                 }}
                             >
                                 <Progress percent={60} />
                             </ConfigProvider>
                             <Typography style={{
                                 color: "white",
                                 textAlign: "left",
                                 fontSize: 24,
                                 fontStyle: "normal",
                                 fontWeight: 700,
                             }}>
                                 Ganhe RED Coins
                             </Typography>
                             <Typography style={{
                                 color: "white",
                                 textAlign: "justify",
                                 fontSize: 16,
                                 fontStyle: "normal",
                                 fontWeight: 600,
                             }}>
                                 Aqui você garante seus RED XP apenas por assistir vídeos e apoiando nossa comunidade nas
                                 principais redes sociais como YouTube e TikTok
                             </Typography>
                         </Flex>
                     </Flex>
                 ) : (
                     <Flex
                         align="center"
                         justify="center"
                         style={{
                             background: "#0a0c0d",
                         }}>
                         <Flex vertical align="center" justify="center">
                             <ImageArtBoard12 width={110} height={144} />
                             <Typography style={{
                                 color: "white",
                                 textAlign: "center",
                                 fontSize: 24,
                                 fontStyle: "normal",
                                 fontWeight: 700,
                             }}>
                                 Rufus
                             </Typography>
                             <Flex vertical style={{ width: "100%" }}>
                                 <ConfigProvider
                                     theme={{
                                         components: {
                                             Progress: {
                                                 defaultColor: "#FE304E"
                                             },
                                         },
                                     }}
                                 >
                                     <Progress percent={60} />
                                 </ConfigProvider>
                                 <Typography style={{
                                     color: "white",
                                     textAlign: "center",
                                     fontSize: 24,
                                     fontStyle: "normal",
                                     fontWeight: 700,
                                 }}>
                                     Ganhe RED Coins
                                 </Typography>
                                 <Typography style={{
                                     color: "white",
                                     textAlign: "center",
                                     fontSize: 16,
                                     fontStyle: "normal",
                                     fontWeight: 600,
                                 }}>
                                     Aqui você garante seus RED XP apenas por assistir vídeos e apoiando nossa comunidade nas
                                     principais redes sociais como YouTube e TikTok
                                 </Typography>
                             </Flex>
                         </Flex>
                     </Flex>
                 )
         }, */
        {
            title: 'Other Actions',
            description: 'Click to see other actions.',
            target: () => ref3.current,
            prevButtonProps: {
                children: "Pular tutorial",
                className: "custom-button2",
                onClick: () => {
                    setOpen(false);
                    setIsModalOpenTwo(true);
                }
            },
            nextButtonProps: {
                children: "Proximo",
                className: "custom-button",
                onClick: () => {
                    setOpen(false);
                    setIsModalOpenTwo(true);
                }
            },
            placement: 'top',
            style: { width: "70%" },
            cover:
                !checkIsMobile ? (
                    <Flex>
                        <Flex vertical align="center" justify="center">
                            <ImageArtBoard12 width={144} height={144} />
                            <Typography style={{
                                color: "white",
                                textAlign: "left",
                                fontSize: 24,
                                fontStyle: "normal",
                                fontWeight: 700,
                            }}>
                                Rufus
                            </Typography>
                        </Flex>
                        <Flex vertical style={{ width: "100%" }}>
                            <ConfigProvider
                                theme={{
                                    components: {
                                        Progress: {
                                            defaultColor: "#FE304E"
                                        },
                                    },
                                }}
                            >
                                <Progress percent={80} />
                            </ConfigProvider>
                            <Typography style={{
                                color: "white",
                                textAlign: "left",
                                fontSize: 24,
                                fontStyle: "normal",
                                fontWeight: 700,
                            }}>
                                Ganhe RED Coins
                            </Typography>
                            <Typography style={{
                                color: "white",
                                textAlign: "justify",
                                fontSize: 16,
                                fontStyle: "normal",
                                fontWeight: 600,
                            }}>
                                Já aqui você garante suas RED Coins, que você poderá trocar por produtos exclusivos em
                                nossa Loja oficial. As missões variam entre missões regulares e raras com recompensas maiores
                            </Typography>
                        </Flex>
                    </Flex>
                ) : (
                    <Flex
                        align="center"
                        justify="center"
                        style={{
                            background: "#0a0c0d",
                        }}>
                        <Flex vertical align="center" justify="center">
                            <ImageArtBoard12 width={110} height={144} />
                            <Typography style={{
                                color: "white",
                                textAlign: "center",
                                fontSize: 24,
                                fontStyle: "normal",
                                fontWeight: 700,
                            }}>
                                Rufus
                            </Typography>
                            <Flex vertical style={{ width: "100%" }}>
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Progress: {
                                                defaultColor: "#FE304E"
                                            },
                                        },
                                    }}
                                >
                                    <Progress percent={80} />
                                </ConfigProvider>
                                <Typography style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: 24,
                                    fontStyle: "normal",
                                    fontWeight: 700,
                                }}>
                                    Ganhe RED Coins
                                </Typography>
                                <Typography style={{
                                    color: "white",
                                    textAlign: "center",
                                    fontSize: 16,
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                }}>
                                    Já aqui você garante suas RED Coins, que você poderá trocar por produtos exclusivos em
                                    nossa Loja oficial. As missões variam entre missões regulares e raras com recompensas maiores
                                </Typography>
                            </Flex>
                        </Flex>
                    </Flex>
                )
        },
    ];

    function saveAlreadyVisited(params) {
        localStorage.setItem("@_already_visited", params)
    }

    useEffect(() => {
        if (session) {
            const bytes = atob(session);
            const jsonParams = JSON.parse(bytes);
            if (!jsonParams)
                navigate("/login")
            if (jsonParams) {
                localStorage.setItem("@_auth_sessions", session)
                navigate("/home")
            }
        } else {
            const sessionLocal = localStorage.getItem("@_auth_sessions")
            if (!sessionLocal) {
                navigate("/login")
            }
        }

        const loginSession = localStorage.getItem("@_auth_sessions");
        if (loginSession) {

            AccountService.returnAccountByEmailAndCodeAccess(
                JSON.parse(atob(loginSession)).email,
                JSON.parse(atob(loginSession)).code_access
            )
                .then((response) => {

                    dispatch(addUser(response.data));
                })
                .catch((error) => {
                    console.log("ERROR GET ACCOUNT API", error);
                    // console.log( JSON.parse(atob(loginSession)).email,
                    // JSON.parse(atob(loginSession)).code_access)
                });
        }

        if (localStorage.getItem("@_already_visited") != "true") {
            setIsModalOpen(true);
        }

    }, []);
    function scrollToElementClassDivChallengeList() {
        const element = document.getElementById("div-challenge-list");
        element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
    function clickDoTour() {
        scrollToElementClassDivChallengeList();
        setIsModalOpen(false);
        setTimeout(() => {
            setOpen(true);
            // navigate("/use-red-coins")
        }, 500);
        // setOpen(true);
    }
    function clickFisrtStep() {
        AccountService.firstAccessPoints(account?._id.$oid, encryptAES("10")).then((response) => {
            setIsModalOpenTwo(false);
            localStorage.setItem("@_already_visited", true)
            window.location.href = "/use-red-coins/true"
        }).catch((error) => {
            console.log("ERROR GET ACCOUNT API", error);
        });
        // AccountService.addRedXpFarmer(account?._id.$oid, account?.red_xp, encryptAES(new Date().toISOString()), 10)
        // AccountService.addRedCoinsFarmer(account?._id.$oid, account?.red_coins, encryptAES(new Date().toISOString()), 10).then((response) => {
        //     setIsModalOpenTwo(false);
        //     localStorage.setItem("@_already_visited", true)
        //     // navigate("/use-red-coins/true")
        //     window.location.href = "/use-red-coins/true"
        //     // setTimeout(() => {
        //     //     var element = document.getElementById("flex-title-product-to-rescue");
        //     //     element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
        //     // }, 1500);
        // }).catch((error) => {
        //     console.log("ERROR GET ACCOUNT API", error);
        // });
    }
    function onChangeTour(e) {
        // if (e === 0) {
        //     scrollToElementClassDivChallengeList();
        // }
    }
    function onCloseFirstTour(e) {
        if (e == 2) {
            setIsModalOpenTwo(true);
        }
    }
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="home-base">
            {/* <script src="//code.jivosite.com/widget/GAi0gGKCfi" async></script> */}
            <JivoSite.Widget id="Em9yNebUef" />
            <MenuDefaultClient page={location} />
            <CoverImage />
            <HomeInfoUserCard />
            <ScoreboardFriends />
            <ChallengeList refTour={ref1} />
            {/* <TitleEarnWathcingVideo />
            <EarnWatchingVideo refTour={ref2} /> */}
            <MissionToWinRedCoins refTour={ref3} showOrder={false} />
            {/* <MoreInfo /> */}
            <Footer />
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    style: {
                        display: "none"
                    }
                }
                }
                cancelButtonProps={{
                    style: {
                        display: "none"
                    }
                }
                }
                closeIcon={false}>
                <ModalHomeWelcome
                    confirm={clickDoTour}
                    cancel={() => {
                        setIsModalOpen(false)
                        saveAlreadyVisited(true)
                    }} />
            </Modal>
            <Modal
                open={isModalOpenTwo}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    style: {
                        display: "none"
                    }
                }
                }
                cancelButtonProps={{
                    style: {
                        display: "none"
                    }
                }
                }
                closeIcon={false}>
                <ModalHomeWelcomeTwo confirm={clickFisrtStep} />
            </Modal>
            <Tour
                open={open} steps={steps} animated={true} scrollIntoViewOptions={true} onFinish={() => { setOpen(false) }} mask={{ color: 'rgba(254, 48, 78, 0.05)' }}
                onChange={(e) => {
                }}
                onClose={(e) => onCloseFirstTour(e)}
                indicatorsRender={() => null}
            />
        </div>
    )
}