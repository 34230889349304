import React from "react"
import "./CardEarnVideos.css"
import TextBoldParagraph from "../../atomos/TextBoldParagraph"
import TextRegularLabel from "../../atomos/TextRegularLabel"
import ImageRedCoins from "../../atomos/ImageRedCoins"
import ImageRedExp from "../../atomos/ImageRedExp"
import TextBoldLabel from "../../atomos/TextBoldLabel"

export default function CardEarnVideos({ title, description, image, platform, totalPoints }) {

    const colors = require("../../../styles/Colors.json")

    return (
        <div className="div-card-earn-videos">
            <div className="div-card-earn-videos-image">
                <img src={image} alt="logo" className="img-card-earn-videos" />
            </div>
            <div className="div-card-earn-videos-info">
                <div className="div-card-earn-videos-info-one">
                    <div className="div-logo-card-earn-videos">
                        <img src={"./images/Marca R_RC_Small.png"} className="logo-card-earn-videos" />
                    </div>
                </div>
                <div className="div-card-earn-videos-info-two">
                    <TextBoldParagraph text={title} colorText={"white"}  />
                    <div>
                        {platform && (
                            <>
                                <TextBoldLabel text={"Plataforma"} colorText={"white"} />
                                <TextBoldLabel text={" "} colorText={"white"} />
                                <TextBoldLabel text={platform} colorText={colors.BaseSlot2} />
                            </>
                        )}
                    </div>
                </div>
                {totalPoints && (
                    <div className="div-card-earn-videos-info-three">
                        <div className="div-win-at-card">
                            <TextBoldLabel text={"Ganhe até"} colorText={"white"} />
                            <TextBoldParagraph text={totalPoints} colorText={"white"} />
                        </div>
                        <div className="div-win-at-card" style={{ marginLeft: 5 }}>
                            <ImageRedExp />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}