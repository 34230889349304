import React, { useEffect, useState } from "react";
import TextBoldH2 from "../../atomos/TextBoldH2";
import {
  Button,
  Card,
  ConfigProvider,
  Flex,
  Menu,
  Segmented,
  Typography,
} from "antd";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  AccountService,
  MissionService,
} from "red-canids-client-module/client-web";
import AccountsAnswers from "../../organisms/AccountsAnswers/AccountsAnswers";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextBoldH5 from "../../atomos/TextBoldH5";
import { Descriptions } from "antd";

// Import statements...

export default function QuestionsTemplate() {
  const [missions, setMissions] = useState([]);
  const [currentMission, setCurrentMission] = useState("");
  const [currentAccount, setCurrentAccount] = useState("");
  const [currentAccountKey, setCurrentAccountKey] = useState("");
  const [accountItems, setAccountItems] = useState([]);
  const [newMissions, setNewMissions] = useState([]);

  const [questionsSelected, setQuestionsSelected] = useState([]);

  useEffect(() => {
    MissionService.returnAllMissionTypeQuestion().then((res) => {
      setNewMissions(res.data);
    }).catch((err) => console.error(err));
    return () => {
      setMissions([]);
      setCurrentMission("");
    };
  }, []);

  const onClickMission = (e) => {
    setCurrentMission(e.key);
    setAccountItems([]);
    const items = newMissions.find((item) => item.mission._id.$oid === e.key);
    setQuestionsSelected(items?.questions);
  };

  // const onClickAccount = (e) => {
  //   setCurrentAccountKey(e.key);
  //   AccountService.returnAllAccountManager()
  //     .then((res) => {
  //       const filteredAccount = res.data.find(
  //         (item) => item._id.$oid === e.key
  //       );
  //       if (filteredAccount) {
  //         const updatedAccount = {
  //           name: filteredAccount.name,
  //           missions_completed: filteredAccount.missions_completed?.filter(
  //             (mission) => mission.id_mission === currentMission
  //           ),
  //         };
  //         setCurrentAccount(updatedAccount);
  //         console.log(updatedAccount);
  //       } else {
  //         console.error("Account not found");
  //       }
  //     })
  //     .catch((err) => console.error(err));
  // };

  return (
    <ConfigProvider
      theme={
        {
          // Theme configuration...
        }
      }
    >
      <Flex style={{}}>
        <Flex
          style={{
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
          }}
          flex={1}
        >
          <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
            Missões
          </Typography.Text>
          <Menu
            onClick={onClickMission}
            selectedKeys={[currentMission]}
            mode="inline"
            items={newMissions.map((item) => ({
              label: item.mission.name_mission,
              key: item.mission._id.$oid,
            }))}
          />
        </Flex>
        {/* <Flex
          style={{
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
          }}
          flex={1}
        >
          {accountItems.length !== 0 ? (
            <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
              Contas
            </Typography.Text>
          ) : null}
          <Menu
            onClick={onClickAccount}
            selectedKeys={[currentAccountKey]}
            mode="inline"
            items={accountItems}
          />
        </Flex> */}
        <Flex
          style={{
            flexDirection: "row",
            width: "100%",
            backgroundColor: "white",
            padding: 10,
            gap: 10,
          }}
          flex={2}
        >
          {/* <AccountsAnswers account={currentAccount} /> */}
          {/* <Descriptions
            title={`Respostas`}
            items={questionsSelected}
            layout="vertical"
            bordered
          /> */}
          {
            Object.keys(questionsSelected).map((question, index) => (
              <Card
                title={`Pergunta - ${question}`}
                bordered={false}
                style={{ width: "auto", height: 500 }}
                key={index}
              >
                <div style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                  {
                    Object.keys(questionsSelected[question]).map((answer, index) => (
                      <>
                        <Typography.Text style={{
                          textAlign: "start",
                          alignSelf: "flex-start"
                        }}>
                          {answer}: <Typography.Text strong>{questionsSelected[question][answer]} </Typography.Text>
                        </Typography.Text>
                        <br />
                      </>
                    ))
                  }
                </div>
              </Card>
            ))
          }
        </Flex>
      </Flex>
    </ConfigProvider>
  );
}
