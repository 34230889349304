import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { v4 as uuid } from "uuid";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const UploadImages = ({
  onDataReceived,
  setImagesList,
  isModalOpen,
  fileList,
  setFileList,
  isEdit,
  urlOnEdit,
  is_enabled,
  setVideoUplaoded
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => {
    console.log(newFileList[0]?.status);
    if (newFileList[0]?.status === "done") {
      setVideoUplaoded(true);
    } else {
      setVideoUplaoded(false)
    }
    var listAdd = {
      uid: uuid,
      name: "",
      status: "",
      url: "",
    };
    setFileList([...newFileList], listAdd);
    onDataReceived(fileList);
  };
  useEffect(() => {
    if (fileList.length > 0) {
      var uris = "";
      fileList.forEach((element) => {
        uris += element?.response?.files[0]?.url;
      });
      setImagesList(uris);
    }
  }, [fileList]);
  useEffect(() => {
    if (isEdit) {
      setFileList([
        {
          uid: "-4",
          name: "image.png",
          status: "done",
          url: urlOnEdit,
        },
      ]);
    } else {
      setFileList([]);
    }
  }, [isEdit]);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        {isEdit && "Alterar "}Imagem da Missão1
      </div>
    </div>
  );
  return (
    <>
      <Upload
        action="https://red-canids-api.azurewebsites.net/image/add-image"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        maxCount={1}
        disabled={false}
      >
        {uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          style={{
            width: "10%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
export default UploadImages;
